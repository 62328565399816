@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,body,div,span,applet,object,
iframe,h1,h2,h3,h4,h5,
h6,p,blockquote,pre,a,abbr,
acronym,address,big,cite,code,del,
dfn,em,img,ins,kbd,q,
s,samp,small,strike,strong,sub,
sup,tt,var,b,u,i,
center,dl,dt,dd,ol,ul,
li,fieldset,form,label,legend,table,
caption,tbody,tfoot,thead,tr,th,
td,article,aside,canvas,details,embed,
figure,figcaption,footer,header,hgroup,menu,
nav,output,ruby,section,summary,time,
mark,audio,video{
  margin:0;
  padding:0;
  border:0;
  vertical-align:baseline
}

article,aside,details,
figcaption,figure,footer,
header,hgroup,menu,
nav,section{
  display:block
}

body,html{
width:100%;
height:100%
}

body{
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  -webkit-text-size-adjust:none;
  margin:0;
  padding:0;
  font-family:"Helvetica Neue",Helvetica,Arial,sans-serif
}

ol,ul{
    list-style:none
}

hr{
    border:0;
    height:1px;
    background:#ccc
}

blockquote,q{
    quotes:none
}

blockquote:before,blockquote:after,q:before,q:after{
  content:'';
  content:none
}

table{
  border-collapse:collapse;
  border-spacing:0
}

*{
  margin:0;
  padding:0;
  list-style:none;
  box-sizing:border-box
}

html,input,select,
textarea,button,a{
  -webkit-tap-highlight-color:rgba(0,0,0,0)
}

:root{
  --text: #000;
  --text-trans: rgba(0, 0, 0, .5);
  --background: #fff;
  --mid: rgb(127, 127, 127);
}

h1{
  font-family: 'Dancing Script', cursive;
}

body{
  background: #fff;
  background: var(--background);
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Hero{display:flex;align-items:center;justify-content:center;flex-direction:column;min-height:30vh;padding-top:1rem}.Hero-logo{width:5rem;height:5rem;display:flex;align-items:center;justify-content:center;border-radius:2.5rem;border:2px solid var(--text);margin-bottom:1rem}.Hero-header{margin-bottom:1rem}.Hero-instagram{display:flex;align-items:center;justify-content:center;margin-bottom:1rem;opacity:.8;transition:all ease 300ms}.Hero-instagram:hover{cursor:pointer;opacity:1}.Hero-instagram svg{width:2rem;height:2rem}.Hero-instagram svg path{fill:var(--mid)}

.FeedContainer{position:relative;display:flex;flex-wrap:wrap;justify-content:center}.FeedContainer-modal{z-index:99999;position:fixed;display:flex;align-items:center;justify-content:center;top:0;left:0;background:var(--text-trans);width:100%;height:100%;opacity:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;pointer-events:none;transition:all ease 300ms}.FeedContainer-modal.open{opacity:1;-webkit-user-select:auto;-moz-user-select:auto;-ms-user-select:auto;user-select:auto;pointer-events:all}.FeedContainer-modal-container{min-width:320px;min-height:320px;border-radius:4px;width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;max-width:90%;max-height:90%;padding:.66rem}.FeedContainer-item{position:relative;width:100%;display:flex;align-items:center;justify-content:center;padding:5px 10px;max-width:420px;max-height:420px;opacity:0;transition:ease 1300ms opacity}.FeedContainer-item.reveal{opacity:.8}.FeedContainer-item:hover{cursor:pointer;opacity:1}.FeedContainer-item-image{display:block;position:relative;width:100%;height:100%;object-fit:cover}

.Header{height:80px;width:100%}.Header-container{height:80px;position:fixed;width:100%;top:0;left:0;padding:2rem;background:var(--background);z-index:999}.Header-container,.Header-container *{display:flex;align-items:center;justify-content:space-between}.Header-fadeEdge{position:absolute;left:0;width:100%;z-index:1;height:3rem;top:100%;background:linear-gradient(180deg, var(--background), rgba(255,255,255,0))}.Header-fadeEdge::after{content:"";height:4rem;position:absolute;left:0;width:100%;z-index:1;top:0;background:linear-gradient(180deg, var(--background), rgba(255,255,255,0))}.Header-logo svg{width:32px;height:32px}

