.FeedContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &-modal{
    z-index: 99999;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: var(--text-trans);
    width: 100%;
    height: 100%;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transition: all ease 300ms;

    &.open{
      opacity: 1;
      user-select: auto;
      pointer-events: all;
    }

    &-container{
      // background: var(--background);
      min-width: 320px;
      min-height: 320px;
      border-radius: 4px;
      width: 100%;
      height: fit-content;
      max-width: 90%;
      max-height: 90%;
      padding: .66rem;
    }
  }
  &-item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    max-width: 420px;
    max-height: 420px;
    opacity: 0;
    transition: ease 1300ms opacity;
    &.reveal{
      opacity: .8;
    }
    
    &:hover{
      cursor: pointer;
      opacity: 1;
    }
    &-image {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
