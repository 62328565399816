@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

:root{
  --text: #000;
  --text-trans: rgba(0, 0, 0, .5);
  --background: #fff;
  --mid: rgb(127, 127, 127);
}

h1{
  font-family: 'Dancing Script', cursive;
}

body{
  background: var(--background);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
