html,body,div,span,applet,object,
iframe,h1,h2,h3,h4,h5,
h6,p,blockquote,pre,a,abbr,
acronym,address,big,cite,code,del,
dfn,em,img,ins,kbd,q,
s,samp,small,strike,strong,sub,
sup,tt,var,b,u,i,
center,dl,dt,dd,ol,ul,
li,fieldset,form,label,legend,table,
caption,tbody,tfoot,thead,tr,th,
td,article,aside,canvas,details,embed,
figure,figcaption,footer,header,hgroup,menu,
nav,output,ruby,section,summary,time,
mark,audio,video{
  margin:0;
  padding:0;
  border:0;
  vertical-align:baseline
}

article,aside,details,
figcaption,figure,footer,
header,hgroup,menu,
nav,section{
  display:block
}

body,html{
width:100%;
height:100%
}

body{
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  -webkit-text-size-adjust:none;
  margin:0;
  padding:0;
  font-family:"Helvetica Neue",Helvetica,Arial,sans-serif
}

ol,ul{
    list-style:none
}

hr{
    border:0;
    height:1px;
    background:#ccc
}

blockquote,q{
    quotes:none
}

blockquote:before,blockquote:after,q:before,q:after{
  content:'';
  content:none
}

table{
  border-collapse:collapse;
  border-spacing:0
}

*{
  margin:0;
  padding:0;
  list-style:none;
  box-sizing:border-box
}

html,input,select,
textarea,button,a{
  -webkit-tap-highlight-color:rgba(0,0,0,0)
}
