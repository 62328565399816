.Hero{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 30vh;
  padding-top: 1rem;

  &-logo{
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2.5rem;
    border: 2px solid var(--text); 
    margin-bottom: 1rem;
  }

  &-header{
    margin-bottom: 1rem;
  }
  
  &-instagram{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    opacity: .8;
    transition: all ease 300ms;
    &:hover{
      cursor: pointer;
      opacity: 1;
    }
    svg{
      width: 2rem;
      height: 2rem;
      path{
        fill: var(--mid);
      }
    }
  }

}