.Header{
  height: 80px;
  width: 100%;

  &-container{
    height: 80px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 2rem;
    background: var(--background);
    z-index: 999;
    &,*{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-fadeEdge{
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 3rem;
    top: 100%;
    background: linear-gradient(180deg,var(--background),hsla(0,0%,100%,0));
    &::after{
      content: "";
      height: 4rem;
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 1;
      top: 0;
      background: linear-gradient(180deg,var(--background),hsla(0,0%,100%,0));
    }
  }

  &-logo{
    svg{
      width: 32px;
      height: 32px;
    }
  }
}